import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getFirestore, doc, getDoc, updateDoc, Timestamp, orderBy,getDocs,query,collection,
  where } from "firebase/firestore";
import dayjs from "dayjs";

function EditMachineProduct() {
  let params = useParams();
  let navigate = useNavigate();
  const machineId = params.machineId;
  const productReference = params.productId;
  let [data, setData] = useState(null);
  const [productName, setProductName] = useState(null);
const [products, setProducts] = useState({});


  useEffect(() => {
    getDoc(
      doc(getFirestore(), `machines/${machineId}/products`, params.productId)
    ).then(async (docSnap) => {
      const data = docSnap.data();

      if (data.reference) {
        const name = await getDoc(data.reference)
          .then((doc) => {
            return doc.data().name;
          })
          .catch((error) => {
            console.log(error);
            return null;
          });

        if (name !== null) {
          setProductName(name);
        }
      }
      data.id = params.productId;
      setData(data);
    });
  }, [params.productId, machineId]);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setData({ ...data, [name]: value });
  };

  const handleLocationChange = (event, locationIndex, type) => {
    let value;
    let name;

    if (type === "Date") {
      value = new Date(event.$d);
      name = "end_date";
    } else {
      const target = event.target;
      value = target.value;
      name = target.name;
    }

    const location = data.location[locationIndex];
    if (type === "Date") {
      location[name] = value;
    } else {
      if (name !== "available") {
        location[name] = parseInt(value);
      } else {
        location[name] = value;
      }
    }

    data.reference =  doc(
              getFirestore(),
              "products/" +
                (data.product_reference
                  ? data.product_reference
                  : Object.keys(products[0]).id)
            );
    setData({
      ...data,
      location: [
        ...data.location.slice(0, locationIndex),
        location,
        ...data.location.slice(locationIndex + 1),
      ],
    });
  };

  const onClose = () => {
    navigate(`/machines/${machineId}/products`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formatLocation = data.location.map((location) => {
      return {
        stock: parseInt(location.stock),
        tray: parseInt(location.tray),
        channel: parseInt(location.channel),
        end_date: location.end_date,
        available: location.available,
        dispense_speed: 9,
        dispense_afterrun: 0,//parseInt(location.dispense_afterrun),
      };
    });
    console.log(data.location);

    console.log('badge:');
    console.log(data.badge);
    const formattedData = {
      price: parseFloat(data.price),
      location: formatLocation,
      reference: data.reference,
      ...(data.display_order && { display_order: parseInt(data.display_order) }),
      ...(data.discount_price && {
        discount_price: parseFloat(data.discount_price),
      }),
      //...(data.badge && { badge: data.badge === ''?'':data.badge }),
      ...( { badge: (data.badge === undefined ?'':data.badge )}),
    };
    
    console.log(formattedData);

    updateDoc(
      doc(getFirestore(), `machines/${machineId}/products`, params.productId),
      formattedData
    ).then(() => {
      navigate(`/machines/${machineId}/products`);
    });
  };

  const removeLocation =async (index)=>{
                          let newData = {...data,
                          location: [
                            ...data.location.slice(0, index),
                            ...data.location.slice(index + 1),]
                        };
                          console.log(data);
                         // setData({newData}
                          //  );
                          console.log(data);

                          const formatLocation = newData.location.map((location) => {
                            return {
                              stock: parseInt(location.stock),
                              tray: parseInt(location.tray),
                              channel: parseInt(location.channel),
                              end_date: location.end_date,
                              available: location.available,
                              dispense_speed: 5,
                              dispense_afterrun: parseInt(location.dispense_afterrun),
                            };
                          });
                      
                          const formattedData = {
                            price: parseFloat(data.price),
                            location: formatLocation,
                            reference: data.reference,
                            ...(data.display_order && { display_order: parseInt(data.display_order) }),
                            ...(data.discount_price && {
                              discount_price: parseFloat(data.discount_price),
                            }),
                            ...(data.badge && { badge: data.badge }),
                          };
                          console.log(formattedData);
                      
                          updateDoc(
                            doc(getFirestore(), `machines/${machineId}/products`, params.productId),
                            formattedData
                          ).then(() => {
                            navigate(`/machines/${machineId}/products`);
                          });
                        }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">
        Edit machine product {productName ?? ""}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {data && (
          <form onSubmit={(e) => handleSubmit(e)}>
            <Table size="small" sx={{ maxWidth: 800 }}>
              <TableBody>
                
                {data.location.map((location, index) => (

                  <TableRow key={index}>
                    {console.log(data)}
                    {console.log(location)}
                    {console.log(index)}
                    <TableCell></TableCell>
                    <TableCell><b>Location {index + 1}</b><br />
                      Stock*
                      <br />
                      <TextField
                        style={{ width: "200px" }}
                        name="stock"
                        type="number"
                        defaultValue={location.stock.toString()}
                        required
                        onChange={(e) => {
                          handleLocationChange(e, index);
                        }}
                      />
                      <br />
                      <br />
                      Tray*
                      <br />
                      <TextField
                        style={{ width: "200px" }}
                        name="tray"
                        type="number"
                        defaultValue={location.tray.toString()}
                        required
                        onChange={(e) => {
                          handleLocationChange(e, index);
                        }}
                      />
                      <br />
                      <br />
                      Channel*
                      <br />
                      <TextField
                        style={{ width: "200px" }}
                        name="channel"
                        type="number"
                        defaultValue={location.channel.toString()}
                        required
                        onChange={(e) => {
                          handleLocationChange(e, index);
                        }}
                      />
                      <br />
                      <br />
                      {/* Product orientation *<br />
                          <i>If the product is standing, we adjust the dispense speed and dispense after run</i>
                          <br />
                          <Select
                            style={{ width: "200px" }}
                            name="dispense_afterrun"
                            defaultValue={location.dispense_afterrun}
                            required
                            onChange={(e) => {
                              handleLocationChange(e, index);
                            }}
                          >
                            <MenuItem value={3} >flat</MenuItem>
                            <MenuItem value={0}>standing</MenuItem>
                          </Select>
                          <br />
                          <br /> */}
                      Enddate*
                      <br />
                      <DatePicker
                        name="end_date"
                        defaultValue={
                          location.end_date
                            ? dayjs(new Date(location.end_date.seconds * 1000))
                            : dayjs()
                        }
                        onChange={(e) => {
                          handleLocationChange(e, index, "Date");
                        }}
                      />
                      <br />
                      <br />
                      Available*
                      <br />
                      {console.log(location.available)}
                      <Select
                        style={{ width: "200px" }}
                        name="available"
                        required
                        defaultValue={location.available?? false}
                        onChange={(e) => {
                          handleLocationChange(e, index);
                        }}
                      >
                        <MenuItem value={true}>True</MenuItem>
                        <MenuItem value={false}>False</MenuItem>
                      </Select><br />
                      <Button
                        onClick={(e) => {
                          removeLocation(index);
                          
                          
                        }}
                      >
                        Delete location
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow><TableCell></TableCell><TableCell><Button
                  onClick={() => {
                    const newLocation = { stock: 0, tray: 0, channel: 0, end_date: new Timestamp(Date.now() / 1000, 0), available: false };
                    console.log(data.location);
                    console.log(newLocation);
                    setData({
                      ...data,
                      location: [
                        ...(data.location ? data.location : []),
                        newLocation,
                      ],
                    });
                  }}
                >
                  Add location
                </Button></TableCell></TableRow>
                <TableRow>
                  <TableCell>New Badge</TableCell>
                  <TableCell>
                    <TextField
                      style={{ width: "200px" }}
                      defaultValue={data.badge}
                      name="badge"
                      type="text"
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Price*</TableCell>
                  <TableCell>
                    <TextField
                      style={{ width: "200px" }}
                      name="price"
                      type="number"
                      defaultValue={data.price}
                      required
                      inputProps={{
                        step: "0.01",
                      }}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Discount price</TableCell>
                  <TableCell>
                    <TextField
                      style={{ width: "200px" }}
                      name="discount_price"
                      defaultValue={data.discount_price}
                      type="number"
                      inputProps={{
                        step: "0.01",
                      }}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Display Order</TableCell>
                  <TableCell>
                    <TextField
                      style={{ width: "200px" }}
                      defaultValue={data.display_order}
                      name="display_order"
                      type="number"
                      inputProps={{
                        step: "1",
                      }}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    />
                  </TableCell>
                </TableRow>

              </TableBody>
            </Table>
            <Button
              style={{ marginTop: "20px" }}
              type="submit"
              variant="contained"
            >
              Save changes
            </Button>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default EditMachineProduct;
